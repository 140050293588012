import { Injectable } from '@angular/core';
import { LineAssociation } from 'src/app/model/line-association/line-association';
import { ProductDiscount } from 'src/app/model/product-catalog/product-discount';
import { ProductPromotionalCode } from 'src/app/model/product-catalog/product-promotional-code';
import { Register } from 'src/app/model/leads/register';
import { ProductAditional } from 'src/app/model/product-catalog/product-aditional';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CustomerModel } from 'src/app/model/customer/identification';
import { CatalogItem } from 'src/app/model/product-catalog/product';
import { ProductCatalog } from 'src/app/model/product-catalog/product-catalog';

/**
   * Servicio para el envío de datos entre componentes
   *
   * @author SW CONSULTING
   *        Ricardo Ayala Martínez <br>
   *        Email: rayala@swat-it.co <br>
   *
   */
@Injectable({
  providedIn: 'root'
})
export class DataService {

  /**
   * Método constructor
   *
   * @author SW CONSULTING
   *        Ricardo Ayala Martínez <br>
   *        Email: rayala@swat-it.co <br>
   *
   */
  constructor(
    public jwtHelper: JwtHelperService
  ) { }

  /**
   * Método para obtener el username desde token
   */
  getUsername(): string {
    const localUser = localStorage.getItem('username');

    const token = localStorage.getItem('access_token');
    if (!token) {
        return localUser;
    }

    const decodedToken = this.jwtHelper.decodeToken(token);
    if (!decodedToken) {
      return localUser;
    }

    return decodedToken.sub;
  }

  /**
   * Método que permite actualizar el valor de la categoría seleccionada
   */
  updateSelectedCategory(category: number) {
    sessionStorage.setItem('categoryId', category.toString());
  }

  /**
   * Método que permite actualizar el nombre de la categoría seleccionada
   */
  updateCategorySelection(category: string) {
    sessionStorage.setItem('categoryName', category);
  }

  /**
   * Retorna la categoria seleccionada
   */
  getSelectedCategory(): string {
    return sessionStorage.getItem('categoryId');
  }

  updateSelectedCatalogItem(catalog: CatalogItem | ProductAditional) {
    sessionStorage.setItem('catalog', JSON.stringify(catalog));
  }

  /**
   * Método que permite setear el descuento de un catalogo
   * en el session storage
   * @param discount el descuento del catalogo (puede ser null)
   */
  updateSelectedDiscount(discount: ProductDiscount) {
    sessionStorage.setItem('discount', JSON.stringify(discount));
  }

  /**
   * Método que obtiene un descuento del session storage
   */
  getSelectedDiscount(): ProductDiscount {
    return JSON.parse(sessionStorage.getItem('discount'));
  }

  /**
   * Método que actualiza el codigo promocional
   * @param promotionalCode el codigo que quieren guardar
   */
  updatePromotionalCode(promotionalCode: ProductPromotionalCode) {
    sessionStorage.setItem('promotionalCode', JSON.stringify(promotionalCode));
  }

  /**
   * Método que obtiene el codigo de promocion del session storage
   */
  getPromotionalCode(): ProductPromotionalCode {
    return JSON.parse(sessionStorage.getItem('promotionalCode'));
  }

  getSelectedCatalogItem(): CatalogItem {
    return JSON.parse(sessionStorage.getItem('catalog'));
  }

  updateSelectedSimCatalogItem(simcatalog: CatalogItem) {
    sessionStorage.setItem('simcatalog', JSON.stringify(simcatalog));
  }

  getSelectedSimCatalogItem(): CatalogItem {
    return JSON.parse(sessionStorage.getItem('simcatalog'));
  }

  /**
   * Método que permite actualizar los valores ingresados en la recarga
   */
  updateRechargeValues(recharge: any) {
    sessionStorage.setItem('recharge', JSON.stringify(recharge));
  }


  /**
   * Retorna los valores ingresados en el formulario de recargas
   */
  getRechargeValues(): any {
    return JSON.parse(sessionStorage.getItem('recharge'));
  }

  /**
   * Método que permite actualizar los valores ingresados en el formulatio de msisdn
   */
  updateMsisdnForm(msisdnForm: any) {
    console.log('updateMsisdnForm', msisdnForm);
    sessionStorage.setItem('msisdnForm', JSON.stringify(msisdnForm));
  }


  /**
   * Retorna los valores ingresados en el formulario de msisdn
   */
  getMsisdnForm(): any {
    const getMsisdnForm = JSON.parse(sessionStorage.getItem('msisdnForm'));
    console.log('getMsisdnForm', getMsisdnForm);
    return getMsisdnForm;
  }

  getCustomer(): CustomerModel {
    return JSON.parse(sessionStorage.getItem('customer'));
  }

  updateCustomer(customer: CustomerModel) {
    sessionStorage.setItem('customer', JSON.stringify(customer));
  }

  /**
   * Método que permite actualizar los valores del perfil del usuario
   */
  updateProfile(profile: any) {
    sessionStorage.setItem('profile', JSON.stringify(profile));
  }


  /**
   * Retorna los valores del perfil del usuario
   */
  getProfile(): any {
    return JSON.parse(sessionStorage.getItem('profile'));
  }

  /**
   * Método que permite actualizar la informacion del prestamo
   */
  updateLoan(loan: number) {
    sessionStorage.setItem('loan', loan + '');
  }

  /**
   * Retorna la informacion del prestamo
   */
  getLoan(): string {
    return sessionStorage.getItem('loan');
  }

  /**
   * Método que permite actualizar la informacion del metodo de pago seleccionado
   */
  updatePaymentMethod(paymentMethod: any) {
    sessionStorage.setItem('paymentMethod', JSON.stringify(paymentMethod));
  }

  /**
   * Retorna la informacion del prestamo
   */
  getPaymentMethod(): any {
    return JSON.parse(sessionStorage.getItem('paymentMethod'));
  }

  /**
   * Método que permite actualizar si la tokenizacion se realizo para la transaccion
   */
  updateTransactionTokenization(txTokenization: any) {
    sessionStorage.setItem('txTokenization', JSON.stringify(txTokenization));
  }

  /**
   * Retorna la informacion de tokenization por transaction
   */
  getTransactionTokenization(): any {
    return JSON.parse(sessionStorage.getItem('txTokenization'));
  }

  /**
   * Método que permite actualizar si el agendamiento se realizo para la transaccion
   */
  updateTransactionScheduled(txScheduled: any) {
    sessionStorage.setItem('txScheduled', JSON.stringify(txScheduled));
  }

  /**
   * Retorna la informacion del agendamiento por transaccion
   */
  getTransactionScheduled(): any {
    return JSON.parse(sessionStorage.getItem('txScheduled'));
  }

  /**
   * Método que permite actualizar la informacion de la linea seleccionada
   */
  updateLineAssociationSelected(lineAssociation: LineAssociation) {
    localStorage.setItem('lineAssociation', JSON.stringify(lineAssociation));
  }

  /**
   * Retorna la informacion de la linea seleccionada
   */
  getLineAssociationSelected(): LineAssociation {
    const storedData = localStorage.getItem('lineAssociation');

    if (!storedData || storedData === 'undefined') {
      console.warn("No se encontró 'lineAssociation' en el localStorage.");
      return null;
    }

    return JSON.parse(storedData);
  }

  /**
  * Método que permite agregar la lista de AddOn
  */
  updateAddOnList(aditionalList: any[], isPersonaliza?: boolean) {
    sessionStorage.setItem('addOnList', JSON.stringify(aditionalList));
    if (isPersonaliza) {
      sessionStorage.setItem('isPersonaliza', 'true');
    } else {
      sessionStorage.removeItem('isPersonaliza');
    }
  }

  /**
   * Retorna la lista de AddOn seleccionado
   */
  getAddOnList(): any[] {
    return JSON.parse(sessionStorage.getItem('addOnList'));
  }

  updateLeadsProduct(product: CatalogItem) {
    if (!product && this.getLeadsProductItem()) {
      sessionStorage.setItem('leadsProductOld', sessionStorage.getItem('leadsProduct'));
    } else {
      sessionStorage.setItem('leadsProductOld', null);
    }
    sessionStorage.setItem('leadsProduct', JSON.stringify(product));
  }

  getLeadsProductItem():CatalogItem {
    return JSON.parse(sessionStorage.getItem('leadsProduct'));
  }

  updateLeadsLogisticProduct(product: any) {
    sessionStorage.setItem('leadsLogisticProduct', JSON.stringify(product));
  }

  /**
   * @author Jonathan Arroyo Reina
   * @description Contiene el producto anteriormente seleccionado en el procedo de Portabilidad (Leads)
   * @returns El producto seleccionado
   */
  getLeadsProductOld(): CatalogItem {
    return JSON.parse(sessionStorage.getItem('leadsProductOld'));
  }
  getPersonaliza(): boolean {
    return sessionStorage.getItem('isPersonaliza') ? true : false;
  }


  /**
   * Limpia todos los valores del session storage
   */
  deleteAllValues() {
    console.log('borrando cache session....');
    sessionStorage.clear();
  }

  public updateLeadsRegisterID(register: number): void {
    sessionStorage.setItem('leadsRegister', String(register));
  }

  public getLeadsRegisterID(): number {
    return +sessionStorage.getItem('leadsRegister');
  }

  public getLeadsRegister(): Register {
    console.log('dataService getLeadsRegister', JSON.parse(sessionStorage.getItem('lead-register')));
    return JSON.parse(sessionStorage.getItem('lead-register'));
  }

  public updateLeadsRegister(register: Register): void {
    console.log('dataService updateLeadsRegister', JSON.stringify(register));
    sessionStorage.setItem('lead-register', JSON.stringify(register));
  }

  /**
   * @author Jonathan Arroyo Reina
   * @description actualiza el valor de la session donde contiene el valor de la url de regreso
   * @param url url donde proviene el back
   */
  public updateLeadsUrlBack(url: string): void {
    if (url) {
      sessionStorage.setItem('lead-back-url', url);
    } else {
      sessionStorage.removeItem('lead-back-url');
    }
  }

  public getLeadsUrlBack(): string {
    return sessionStorage.getItem('lead-back-url');
  }

  public getCountTryLeads(msisdn: number): number {
    if (sessionStorage.getItem('lead-count-try' + msisdn)) {
      return +sessionStorage.getItem('lead-count-try' + msisdn);
    }
    return 0;
  }

  public inrementTryLeads(msisdn: number): void {
    sessionStorage.setItem('lead-count-try' + msisdn, (this.getCountTryLeads(msisdn) + 1).toString());
  }

  public resetCountTryLeads(msisdn): void {
    sessionStorage.removeItem('lead-count-try' + msisdn);
  }


  public setPortabilityToken(token: string): void {
    console.debug('setPortabilityToken', token);
    sessionStorage.setItem('portability-token', token);
  }

  public getPortabilityToken(): string {
    console.debug('getPortabilityToken', sessionStorage.getItem('portability-token'));
    return sessionStorage.getItem('portability-token').toString();
  }

  // TODO: INTB1-5 eliminar al finalizar
  public updateSelectedSimCatalog(simcatalog: ProductCatalog) {
    sessionStorage.setItem('simcatalog', JSON.stringify(simcatalog));
  }

  // TODO: INTB1-5 eliminar al finalizar
  public updateSelectedCatalog(catalog: ProductCatalog | ProductAditional) {
    sessionStorage.setItem('catalog', JSON.stringify(catalog));
  }

}
